@use '../../scss/abstracts/' as *;

.topnav {
    @include flex(center, space-between);
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }

    // To hide the menu icon on larger devices
    .sidebar-toggle {
        display: none;

        @include tablet {
            display: flex; // Show only on small devices
        }
    }
}

.loading-screen {
  position: fixed; // Fixed to cover the whole screen
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); // Dark overlay
  color: white;
  display: flex;
//   border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; // Font size
  z-index: 1000; // High z-index to cover other elements
}

.store-dropdown {
  position: relative;

  .dropdown-button {
    background-color: rgb(236, 233, 233); // Lighter purple for the button
    color: #9b59b6;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-weight: bold;
    display: flex; // Make button a flex container
    align-items: center; // Center content vertically

    .dropdown-icon {
      margin-left: 10px; // Space between button text and icon
      font-size: 1.2rem; // Adjust icon size
      color: #9b59b6; // Icon color to match the button
    }

    &:hover {
      background-color: #cacaca; // Darker shade on hover
      transform: translateY(-2px); // Slight lift effect
    }
  }

  .dropdown-menu {
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 8px;
    z-index: 1000;
    overflow: hidden;

    .store-option,
    .add-store-button {
      display: block;
      padding: 10px 20px;
      border: none;
      width: 100%;
      text-align: left;
      background: transparent;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f0f0f0; // Light grey on hover
      }
    }

    .add-store-button {
      background-color: #8e44ad; // Darker purple for the button
      color: white;
      font-weight: bold;

      &:hover {
        background-color: #732d91; // Even darker shade on hover
      }
    }
  }
}

.icon {
  font-size: 40px;
  color: #e0e0e0; // Light color for the icon
  transition: color 0.3s;

  &:hover {
    color: white; // Change icon color on hover
  }
}

body.sidebar-open {
    // overflow: hidden; // Prevent scrolling when sidebar is open
  }
  
  // Sidebar styles
  .sidebar {
    // display: none; // Initially hide sidebar
    // Add your sidebar styles here
  
    &.sidebar-open {
      display: block; // Show sidebar when class is applied
    }
  }
  

  
  .no-more-stores {
    padding: 10px 20px;
    color: #666; // Grey color for the message
    text-align: center; // Centered text
  }
  